import { Injectable } from '@angular/core';

// declare let paymentV2AB;
declare let story_one_tap_AB;
declare let slab_AB;
declare let repeatorder_ministoryAB;
declare let thankyou_standard_AB;
declare let payu_AB;
declare let bulk_donation_hf_mini_AB;
declare let dm_AB;
declare let quickDonate_fallback_p1_AB;
declare let quickDonate_fr_AB;
declare let kcart_fr_urgent_tag_AB;
declare let story_variation_5;
declare let tip_desktop_story_AB;
declare let kcart_tip_story_AB; // (vwo.kcart_tip_story_AB() && vars.showKCart) remove in tip comp after result
declare let kcart_slab_AB;
declare let kcart_stories_ctachange_AB;
declare let kcart_not_preselected_AB;
declare let csat_typ_AB;
declare let phonepe_qr_AB;
declare let fundraiser_insurance_AB;
declare let bulk_donate_kcart;
declare let kcart_default_unit_AB;

@Injectable({
  providedIn: 'root'
})
export class VwoService {

  constructor() { }

  // paymentV2AB() {
  //   if (typeof paymentV2AB !== 'undefined') {
  //     return paymentV2AB;
  //   }
  // }

  story_one_tap_AB() {
    if (typeof story_one_tap_AB !== 'undefined') {
      return story_one_tap_AB;
    }
  }

  slab_AB() {
    if (typeof slab_AB !== 'undefined') {
      return slab_AB;
    }
    // return 'non_inr_ab'
  }

  repeatorder_ministoryAB() {
    if (typeof repeatorder_ministoryAB !== 'undefined') {
      return repeatorder_ministoryAB;
    }
  }

  thankyou_standard_AB() {
    if (typeof thankyou_standard_AB !== 'undefined') {
      return thankyou_standard_AB;
    }
  }

  payu_AB() {
    if (typeof payu_AB !== 'undefined') {
      return payu_AB;
    }
  }

  bulk_donation_hf_mini_AB() {
    if (typeof bulk_donation_hf_mini_AB !== 'undefined') {
      return bulk_donation_hf_mini_AB;
    }
  }

  dm_AB() {
    if (typeof dm_AB !== 'undefined') {
      return dm_AB;
    }
  }

  quickDonate_fallback_p1_AB() {
    if (typeof quickDonate_fallback_p1_AB !== 'undefined') {
      return quickDonate_fallback_p1_AB;
    }
  }

  quickDonate_fr_AB() {
    if (typeof quickDonate_fr_AB !== 'undefined') {
      return quickDonate_fr_AB;
    }
  }

  kcart_fr_urgent_tag_AB() {
    if (typeof kcart_fr_urgent_tag_AB !== 'undefined') {
      return kcart_fr_urgent_tag_AB;
    }
  }

  story_variation_5() {
    if (typeof story_variation_5 !== 'undefined') {
      return story_variation_5;
    }
  }

  tip_desktop_story_AB() {
    if (typeof tip_desktop_story_AB !== 'undefined') {
      return tip_desktop_story_AB;
    }
  }

  kcart_tip_story_AB() {
    if (typeof kcart_tip_story_AB !== 'undefined') {
      return kcart_tip_story_AB;
    }
  }

  kcart_slab_AB() {
    if (typeof kcart_slab_AB !== 'undefined') {
      return kcart_slab_AB;
    }
  }

  kcart_stories_ctachange_AB(){
    if(typeof kcart_stories_ctachange_AB !== 'undefined'){
      return kcart_stories_ctachange_AB;
    }
  }

  kcart_not_preselected_AB() {
    if (typeof kcart_not_preselected_AB !== 'undefined') {
      return kcart_not_preselected_AB;
    }
  }

  csat_typ_AB() {
    if (typeof csat_typ_AB !== 'undefined') {
      return csat_typ_AB;
    }
  }

  phonepe_qr_AB() {
    if (typeof phonepe_qr_AB !== 'undefined') {
      return phonepe_qr_AB;
    }
  }

  fundraiser_insurance_AB() {
    if (typeof fundraiser_insurance_AB !== 'undefined') {
      return fundraiser_insurance_AB;
    }
  }

  bulk_donate_kcart() {
    if (typeof bulk_donate_kcart !== 'undefined') {
      return bulk_donate_kcart;
    }
  }
  
  kcart_default_unit_AB() {
    if (typeof kcart_default_unit_AB !== 'undefined') {
      return kcart_default_unit_AB;
    }
  }
}
